import { EventDetail } from '../../components/Event';
import { PublicHomeLayout } from '../../components/PublicHome/Layout';

// eslint-disable-next-line import/no-default-export
export default function MyEventDetail(): JSX.Element | null {
  return (
    <PublicHomeLayout>
      <EventDetail />
    </PublicHomeLayout>
  );
}
